import React from 'react';
import { PrimaryButton, DefaultButton, Selection, Panel, PanelType, elementContainsAttribute, Stack, TextField, Dropdown, IDropdownOption, Toggle, DetailsList, IColumn, Label, ICommandBarItemProps, CommandBar, SelectionMode, IObjectWithKey, Pivot, PivotItem, Text } from '@fluentui/react';
import styles from './SupplierPanel.module.scss';
import { ICreateOrUpdateUserDTO, IRoleDTO, ICreateOrUpdateSupplierDTO, ISBPPersonDTO, ICreateSBPPersonDTO, IRegionDTO, ICertificationDTO } from '../../../models';
import { IUserService, Services } from '../../../services';
import UserPanel, { UserPanelCloseReason } from '../UserPanel/UserPanel';
import { UserServiceProvider } from '../../../services/userService';
import { TooltipComponent } from '../../../helpers/TooltipComponent';

interface ISupplierPanelProps {
    selectedItem?: ICreateOrUpdateSupplierDTO;
    onDismiss(): void;
    onChanged(user: ICreateOrUpdateSupplierDTO): void;
}

interface ISupplierPanelState {
    currentItem: ICreateOrUpdateSupplierDTO;
    canSubmit: boolean;
    SBP_Persons?: ISBPPersonDTO[];
    All_SBP_Persons?: ISBPPersonDTO[];
    selectedSBPPerson?: ISBPPersonDTO;
    newSBPPersonName?: string;
    newSBPPersonEmail?: string;
    newSBPPersonPhone?: string;
    newSBPPersonEducation?: string;
    showNewSBPPersonField: boolean;
    showEditSBPPersonField: boolean;
    regions?: IRegionDTO[];
    certifications?: ICertificationDTO[];
    users?: ICreateOrUpdateUserDTO[];
    allSuppliers?: ICreateOrUpdateSupplierDTO[];
    noChangedCVR?: string;

    selectedUser?: ICreateOrUpdateUserDTO;
    showUserPanel: boolean;
}

export default class SupplierPanel extends React.Component<ISupplierPanelProps, ISupplierPanelState> {
    private userService: IUserService;
    private _selection_Users: Selection;
    private _selection: Selection;

    constructor(props: ISupplierPanelProps) {
        super(props);

        this.userService = Services.UserService.Initialize(new UserServiceProvider());

        this._selection_Users = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedUser: (this._selection_Users.getSelection() as IObjectWithKey | any)[0] as ICreateOrUpdateUserDTO });
            }
        });

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedSBPPerson: (this._selection.getSelection() as IObjectWithKey | any)[0] as ISBPPersonDTO, showEditSBPPersonField: false, showNewSBPPersonField: false });
            }
        });

        this.state = {
            canSubmit: true,
            currentItem: props.selectedItem ? props.selectedItem : { id: "", name: "", isCertified: false, certifications: [], internal: false },
            users: this.props.selectedItem?.users,
            showNewSBPPersonField: false,
            showEditSBPPersonField: false,
            showUserPanel: false
        };

        this.load();
    }

    public render(): React.ReactElement<ISupplierPanelProps> {
        const CommandBar_Users: ICommandBarItemProps[] = [
            {
                key: 'newUser',
                text: 'Ny bruger',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({ selectedUser: undefined, showUserPanel: true }) }
            },
            {
                key: 'editUser',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({ showUserPanel: true }) },
                disabled: !this.state.selectedUser
            }
        ];

        const CommandBar_SBPPersons: ICommandBarItemProps[] = [
            {
                key: 'newSBPPerson',
                text: 'Ny person',
                iconProps: { iconName: 'Add' },
                onClick: () => { this.setState({ showNewSBPPersonField: true, showEditSBPPersonField: false }) }
            },
            {
                key: 'deleteSBPPerson',
                text: 'Slet',
                iconProps: { iconName: 'Delete' },
                onClick: () => { this.deleteSBPPerson(); },
                disabled: !this.state.selectedSBPPerson
            },
            {
                key: 'editSBPPerson',
                text: 'Rediger',
                iconProps: { iconName: 'Edit' },
                onClick: () => { this.setState({ showEditSBPPersonField: true, showNewSBPPersonField: false }) },
                disabled: !this.state.selectedSBPPerson
            }
        ];

        const isSPBEmailValid = (value: string) => {
            if (value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3\})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                return false;
            }

            return true;
        };

        const isEmailAlreadyInUse = (value: string) => {
            if (this.state.All_SBP_Persons != null && this.state.All_SBP_Persons?.findIndex(d => d.email?.toLocaleLowerCase() == value.toLocaleLowerCase()) > -1) {
                return true;
            }

            return false;
        }

        const isPhoneAlreadyInUse = (value: string) => {
            if (this.state.All_SBP_Persons != null && this.state.All_SBP_Persons?.findIndex(d => d.phone?.toLocaleLowerCase() == value.toLocaleLowerCase()) > -1) {
                return true;
            }

            return false;
        }

        const isCvrAlreadyInUse = (value: string) => {
            if (this.state.allSuppliers != null && this.state.allSuppliers?.findIndex(d => d.cvr?.toLocaleLowerCase() == value.toLocaleLowerCase()) > -1) {
                return true;
            }

            return false;
        }

        const GetSBPPersonByEmail = (email?: string) => {

            if (email && this.state.All_SBP_Persons) {
                return this.state.All_SBP_Persons?.find(d => d.email?.toLocaleLowerCase() == email.toLocaleLowerCase());
            }

            return undefined;
        }

        const getSBPPersonByPhone = (phone?: string) => {
            if (phone && this.state.All_SBP_Persons) {
                return this.state.All_SBP_Persons?.find(d => d.phone?.toLocaleLowerCase() == phone.toLocaleLowerCase());
            }

            return undefined;
        }

        const isSBPPersonAlreadyInUse = (value: string) => {
            if (value == undefined || value == "") {
                return false;
            }

            if (this.state.SBP_Persons != null && this.state.SBP_Persons?.findIndex(d => d.email?.toLocaleLowerCase() == value.toLocaleLowerCase()) > -1 || this.state.SBP_Persons != null && this.state.SBP_Persons?.findIndex(d => d.phone?.toLocaleLowerCase() == value.toLocaleLowerCase()) > -1) {
                return true;
            }

            return false;
        }

        return (
            <Panel
                headerText={this.props.selectedItem ? "Rediger leverandør" : "Opret ny leverandør"}
                isOpen={true}
                isBlocking={true}
                className={styles.userPanel}
                isLightDismiss={false}
                onRenderFooterContent={() => {
                    return (<div>
                        <PrimaryButton disabled={!this.state.canSubmit} style={{ marginRight: "10px" }}
                            onClick={() => { this.onAddOrUpdateAssignment() }}
                        >
                            {this.props.selectedItem ? "Gem" : "Opret"}
                        </PrimaryButton>
                        {this.props.selectedItem &&
                            <PrimaryButton
                                className={styles.deleteButton}
                                onClick={async () => { this.deleteSupplier(); }}
                            >
                                Slet leverandør
                            </PrimaryButton>
                        }
                        <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>
                    </div>);
                }
                }
                onDismiss={() => this.props.onDismiss()}
                closeButtonAriaLabel="Luk leverandørpanel"
                type={PanelType.large}
            >
                <Stack>
                    <TextField
                        required={true}
                        label="Navn"
                        placeholder="Angiv navnet på leverandøren"
                        autoComplete="off_navn"
                        value={this.state.currentItem.name}
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, name: newValue ? newValue : "" } }); }}
                    />
                    <TextField
                        required={true}
                        label="CVR"
                        placeholder="Angiv CVR nummer på leverandøren"
                        autoComplete="off_cvr"
                        value={this.state.currentItem.cvr}
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, cvr: newValue ? newValue : "" } }); }}
                        onGetErrorMessage={(value) => {
                            if(this.state.noChangedCVR != "" && this.state.currentItem.cvr != this.state.noChangedCVR && isCvrAlreadyInUse(value.toLocaleLowerCase())) {
                                this.setState({ canSubmit: false });
                                return `CVR nummer er allerede i brug af ${this.state.allSuppliers?.find(d => d.cvr?.toLocaleLowerCase() == value.toLocaleLowerCase())?.name}`;
                            }

                            this.setState({ canSubmit: true });
                        }}
                    />
                    <TextField
                        required={false}
                        label="Projektnummer"
                        placeholder="Angiv projektnummer på leverandøren"
                        autoComplete="off_navn"
                        value={this.state.currentItem.projectNo}
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, projectNo: newValue ? newValue : "" } }); }}
                    />
                    <Dropdown
                        required={false}
                        placeholder="Vælg fra listen"
                        multiSelect={true}
                        label="Certificeret"
                        selectedKeys={this.state.currentItem.certifications?.map(d => d.id)}
                        options={this.state.certifications ? this.state.certifications.map((value: ICertificationDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.onCertificationsChanged(event, option) }}
                    />
                    <Dropdown
                        required={true}
                        placeholder="Vælg fra listen"
                        label="Region"
                        selectedKey={this.state.currentItem.region?.id}
                        options={this.state.regions ? this.state.regions.map((value: IRegionDTO) => { return { key: value.id, text: value.name } }) : [] as IDropdownOption[]}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ currentItem: { ...this.state.currentItem, region: this.state.regions?.filter(d => d.id == option?.key)[0] } }); }}
                    />
                    <TooltipComponent name="Intern HD leverandør" description="Ved intern leverandør menes f.eks. kollegaer i NVP, som selv skal have projekterne over i HD flis og pinfo. Når en intern leverandør vælges på en opgave, kigges der på leverandørens 'e-mail' f.eks. JESRI@hededanmark.dk. Det vil så være JESRI der vil kunne se opgaven i HD’s systemer efter den er godkendt i flisportalen, i stedet for den DL der har godkendt opgaven." />
                    <Toggle checked={this.state.currentItem.internal ? this.state.currentItem.internal : false} onChange={(ev, checked?: boolean) => { this.setState({ currentItem: { ...this.state.currentItem, internal: checked ? checked : false } }); }} />
                    {this.props.selectedItem &&
                        <Pivot>
                            <PivotItem key="sbpPersons" headerText={`Uddannede personer`}>
                                <CommandBar items={CommandBar_SBPPersons} styles={{ root: { paddingLeft: "0px" } }} />
                                {this.state.showNewSBPPersonField &&
                                    <div>
                                        <TextField
                                            required={true}
                                            label="Navn"
                                            placeholder="Navn"
                                            value={this.state.newSBPPersonName}
                                            onChange={(ev, newValue) => { this.setState({ newSBPPersonName: newValue }); }}
                                            disabled={isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "") || isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "")}
                                        />
                                        <TextField
                                            required={true}
                                            label="Telefon"
                                            placeholder="Telefon"
                                            value={this.state.newSBPPersonPhone}
                                            onChange={(ev, newValue) => { this.setState({ newSBPPersonPhone: newValue }); }}
                                            disabled={isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "")}
                                            onGetErrorMessage={(value) => {
                                                if (isPhoneAlreadyInUse(value.toLocaleLowerCase())) {
                                                    return "Telefonnummer er allerede i brug";
                                                }

                                                return "";
                                            }}
                                        />
                                        <TextField
                                            required={true}
                                            label="E-mail"
                                            placeholder="E-mail"
                                            value={this.state.newSBPPersonEmail}
                                            onChange={(ev, newValue) => { this.setState({ newSBPPersonEmail: newValue }); }}
                                            type='email'
                                            disabled={isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "")}
                                            onGetErrorMessage={(value) => {
                                                if (!isSPBEmailValid(value)) {
                                                    return "E-mail er ikke gyldig";
                                                }

                                                if (isEmailAlreadyInUse(value.toLocaleLowerCase())) {
                                                    return "E-mail er allerede i brug";
                                                }

                                                return "";
                                            }}
                                        />
                                        <Dropdown
                                            required={true}
                                            placeholder="Uddannelse"
                                            label="Uddannelse"
                                            options={[{ key: "Maskinføreruddannelse", text: "Maskinføreruddannelse" }, { key: "Leverandøruddannelse", text: "Leverandøruddannelse" }]}
                                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ newSBPPersonEducation: option?.key + "" }); }}
                                            disabled={isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "") || isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "")}
                                        />
                                        {
                                            !isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "") && !isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "") &&
                                            <PrimaryButton
                                                styles={{ root: { marginTop: 10 } }}
                                                disabled={!this.state.newSBPPersonName || !this.state.newSBPPersonEmail || !isSPBEmailValid(this.state.newSBPPersonEmail) || !this.state.newSBPPersonPhone}
                                                onClick={() => { this.addNewSBPPerson() }}>
                                                Tilføj
                                            </PrimaryButton>
                                        }
                                        {
                                            ((isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "") && !isSBPPersonAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "")) || (isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "") && !isSBPPersonAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + ""))) &&
                                            <PrimaryButton
                                                styles={{ root: { marginTop: 10 } }}
                                                onClick={() => {
                                                    let userId: string | undefined = undefined;

                                                    if (isEmailAlreadyInUse(this.state.newSBPPersonEmail?.toLocaleLowerCase() + "")) {
                                                        userId = GetSBPPersonByEmail(this.state.newSBPPersonEmail)?.id;
                                                    } else if (isPhoneAlreadyInUse(this.state.newSBPPersonPhone?.toLocaleLowerCase() + "")) {
                                                        userId = getSBPPersonByPhone(this.state.newSBPPersonPhone)?.id;
                                                    }

                                                    this.addRelationToExistingSBPPerson(userId + "");
                                                }}>
                                                Tilføj eksisterende SBP person
                                            </PrimaryButton>
                                        }
                                    </div>
                                }
                                {
                                    this.state.showEditSBPPersonField && this.state.selectedSBPPerson &&
                                    <div>
                                        <TextField
                                            required={true}
                                            label="Navn"
                                            placeholder="Navn"
                                            value={this.state.selectedSBPPerson.name}
                                            onChange={(ev, newValue) => { this.setState({ selectedSBPPerson: { ...this.state.selectedSBPPerson, name: newValue } }); }}
                                        />
                                        <TextField
                                            required={true}
                                            label="Telefon"
                                            placeholder="Telefon"
                                            value={this.state.selectedSBPPerson.phone}
                                            onChange={(ev, newValue) => { this.setState({ selectedSBPPerson: { ...this.state.selectedSBPPerson, phone: newValue } }); }}
                                        />
                                        <TextField
                                            required={true}
                                            label="E-mail"
                                            placeholder="E-mail"
                                            value={this.state.selectedSBPPerson.email}
                                            onChange={(ev, newValue) => { this.setState({ selectedSBPPerson: { ...this.state.selectedSBPPerson, email: newValue } }); }}
                                            type='email'
                                            onGetErrorMessage={(value) => {
                                                if (!isSPBEmailValid(value)) {
                                                    return "E-mail er ikke gyldig";
                                                }

                                                return "";
                                            }}
                                        />
                                        <Dropdown
                                            required={true}
                                            placeholder="Uddannelse"
                                            label="Uddannelse"
                                            selectedKey={this.state.selectedSBPPerson.education}
                                            options={[{ key: "Maskinføreruddannelse", text: "Maskinføreruddannelse" }, { key: "Leverandøruddannelse", text: "Leverandøruddannelse" }]}
                                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => { this.setState({ selectedSBPPerson: { ...this.state.selectedSBPPerson, education: option?.key + "" } }); }}
                                        />
                                        {
                                            <PrimaryButton
                                                styles={{ root: { marginTop: 10 } }}
                                                disabled={!this.state.selectedSBPPerson.name || !this.state.selectedSBPPerson.email || !isSPBEmailValid(this.state.selectedSBPPerson.email) || !this.state.selectedSBPPerson.phone}
                                                onClick={() => { this.UpdateSBPPerson() }}>
                                                Gem
                                            </PrimaryButton>
                                        }
                                    </div>
                                }
                                <DetailsList
                                    items={this.state.SBP_Persons ? this.state.SBP_Persons : []}
                                    columns={this.Columns_SBPPersons}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    onRenderItemColumn={(item, index, column) => {
                                        if (column?.key === 'educated') {
                                            return <Text>{item.educated ? "Ja" : "Nej"}</Text>;
                                        }

                                        return item[column?.fieldName as keyof ISBPPersonDTO] as string;
                                    }}
                                    selectionPreservedOnEmptyClick={true}
                                />
                            </PivotItem>
                            <PivotItem key="users" headerText={`Brugere`}>
                                <CommandBar items={CommandBar_Users} styles={{ root: { paddingLeft: "0px" } }} />
                                <DetailsList
                                    items={this.state.users ? this.state.users : []}
                                    columns={this.Columns_Users}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection_Users}
                                    selectionPreservedOnEmptyClick={true}
                                />
                                {this.state.showUserPanel && <UserPanel selectedSupplier={this.state.currentItem} selectedUser={this.state.selectedUser} onChanged={(user: ICreateOrUpdateUserDTO, reason) => {
                                    let users = [...this.state.users as ICreateOrUpdateUserDTO[]];

                                    if (reason == UserPanelCloseReason.AddOrUpdate) {
                                        if (user.id == undefined) // New user
                                        {
                                            users.push(user);
                                        } else {
                                            let i = users.findIndex(d => d.id == user.id);
                                            users[i] = user;
                                        }
                                    } else if (reason == UserPanelCloseReason.Delete) {
                                        let i = users.findIndex(d => d.id == user.id);
                                        users.splice(i, 1);
                                    }

                                    this.setState({ users: users, showUserPanel: false });
                                }} onDismiss={() => { this.setState({ showUserPanel: false }); }} />}
                            </PivotItem>
                        </Pivot>
                    }
                </Stack>
            </Panel>
        );
    }

    private async load() {
        let regions = await this.userService.GetRegions();
        let certifications = await this.userService.GetAllCertifications();
        let All_SBP_Persons = await this.userService.GetAllSBPPersons();
        let All_Suppliers = await this.userService.GetSuppliers();

        //Filter out "No"-option
        certifications = certifications.filter(d => d.id != "6a865d11-a949-4658-9872-ea79a7045944");

        if (this.props.selectedItem) {
            let SBP_Persons = await this.userService.GetSBPPersons(this.props.selectedItem.id);

            this.setState({ SBP_Persons: SBP_Persons, regions: regions, certifications: certifications, All_SBP_Persons: All_SBP_Persons, allSuppliers: All_Suppliers, noChangedCVR: this.props.selectedItem.cvr });
        } else {
            this.setState({ regions: regions, certifications: certifications, All_SBP_Persons: All_SBP_Persons, allSuppliers: All_Suppliers });
        }
    }

    private onCertificationsChanged(event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) {
        if (item) {
            let certifications = item.selected ? [...this.state.currentItem.certifications, { id: item.key, name: item.text }] as ICertificationDTO[] : this.state.currentItem.certifications.filter(key => key.id !== item.key) as ICertificationDTO[]

            this.setState({ currentItem: { ...this.state.currentItem, certifications: certifications } });
        }
    }

    private async addRelationToExistingSBPPerson(SBPPersonID: string) {
        let result = await this.userService.CreateRelationship(SBPPersonID, this.state.currentItem.id);
        if (result) {
            this.setState({ newSBPPersonName: undefined, newSBPPersonEmail: undefined, showNewSBPPersonField: false, newSBPPersonPhone: undefined }, () => {
                this.load();
            });
        } else {
            alert("Fejl ved oprettelse af relation til SBP person.");
        }
    }

    private async addNewSBPPerson() {
        let result = await this.userService.CreateSBPPerson({ supplierId: this.state.currentItem.id, name: this.state.newSBPPersonName, email: this.state.newSBPPersonEmail, phone: this.state.newSBPPersonPhone, education: this.state.newSBPPersonEducation } as ICreateSBPPersonDTO);
        if (result) {
            this.setState({ newSBPPersonName: undefined, newSBPPersonEmail: undefined, showNewSBPPersonField: false, newSBPPersonPhone: undefined, newSBPPersonEducation: undefined }, () => {
                this.load();

            });
        } else {
            alert("Fejl ved oprettelse af SBP person.");
        }
    }

    private async deleteSBPPerson() {
        let result = await this.userService.DeleteSBPPerson(this.state.selectedSBPPerson, this.state.currentItem.id);
        if (result) {
            this.load();
        } else {
            alert("Fejl ved sletning af SBP person"); //TODO
        }
    }

    private async UpdateSBPPerson() {
        let result = await this.userService.UpdateSBPPerson(this.state.selectedSBPPerson);
        if (result) {
            this.setState({ showEditSBPPersonField: false, showNewSBPPersonField: false, selectedSBPPerson: undefined }, () => {
                this.load();
            });

        } else {
            alert("Fejl ved opdatering af SBP person"); //TODO
        }
    }

    private async onAddOrUpdateAssignment() {
        let result: boolean = false;
        if (this.props.selectedItem) {
            result = await this.userService.UpdateSupplier(this.state.currentItem);
        } else {
            result = await this.userService.CreateSupplier(this.state.currentItem);
        }

        if (result) {
            this.props.onChanged(this.state.currentItem);
        } else {
            alert("Fejl ved oprettelse eller opdatering af leverandør. Prøv igen senere.");
        }
    }

    private async deleteSupplier() {
        if (window.confirm("Er du sikker på, at du ønsker at slette denne leverandør? Alle tilknyttede brugere og SBP personer slettes også!")) {
            let result: boolean = await this.userService.DeleteSupplier(this.state.currentItem);

            if (result) {
                this.props.onChanged(this.state.currentItem);
            } else {
                alert("Der er sket en fejl ved sletning af leverandøren. Prøv igen senere.");
            }
        }
    }

    private Columns_SBPPersons: IColumn[] = [
        {
            key: 'name',
            name: 'Navn',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200
        },
        {
            key: 'phone',
            name: 'Telefon',
            fieldName: 'phone',
            minWidth: 200,
            maxWidth: 200
        },
        {
            key: 'email',
            name: 'E-mail',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 200
        },
        {
            key: 'education',
            name: 'Uddannelse',
            fieldName: 'education',
            minWidth: 200,
            maxWidth: 200
        },
        {
            key: 'educated',
            name: 'Uddannet',
            fieldName: 'educated',
            minWidth: 200,
            maxWidth: 200
        }
    ];

    private Columns_Users: IColumn[] = [
        {
            key: 'name',
            name: 'Navn',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'email',
            name: 'E-mail',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
        }
    ];
}