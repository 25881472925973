import React from 'react';
import { DefaultButton, Link, MessageBar, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import { IUserService, Services } from '../../services';
import styles from './Login.module.scss';
import { IUser, ILoginRequestDTO, IRequestNewPasswordDTO, IResetPassword } from '../../models';
import { UserServiceProvider } from '../../services/userService';

interface ILoginProps
{
    setToken(user: IUser): void;
}

interface ILoginState
{
  email?: string;
  password?: string;
  showErrorMessage: boolean;
  formType: formType;

  /* Request password flow */
  showRequestPasswordStatus: boolean;

  /* Reset password flow */
  canResetPassword: boolean;
  showResetPasswordStatus: boolean;
  newPassword?: string;
  newPasswordAgain?: string;

  isWorking: boolean;
}

enum formType
{
  login,
  requestPassword,
  activateAccount
}

export default class Dashboard extends React.Component<ILoginProps, ILoginState> {
  private userService : IUserService;
  private prflowId : string | null;

  constructor(props: ILoginProps) {
    super(props);
    
    this.userService = Services.UserService.Initialize(new UserServiceProvider());

    this.prflowId = new URLSearchParams(window.location.search).get("prflowId");
    let isInvitationLink = new URLSearchParams(window.location.search).get("acceptinvitation");
    let currentFormType : formType = formType.login;

    if(isInvitationLink != undefined)
    {
      currentFormType = formType.requestPassword;
    }

    if(this.prflowId != undefined)
    {
      currentFormType = formType.activateAccount;
    }

    this.state = {
      email: "",
      password: "",      
      formType: currentFormType,
      showRequestPasswordStatus: false,
      showErrorMessage: false,
      showResetPasswordStatus: false,
      canResetPassword: false,
      isWorking: false
    };
  }

    public render(): React.ReactElement<ILoginProps> {
      if(this.state.formType == formType.login)
      {
          return (<div className={styles.login}>
            <form onSubmit={this.onSubmit.bind(this)}>
              <h3>Log på Flisportalen</h3>
                  <TextField required={true} className={styles.marginBottom} placeholder="E-mail" value={this.state.email} onChange={(ev, newValue) => { this.setState({email: newValue}); }} />              
                  <TextField required={true} className={styles.marginBottom} placeholder="Adgangskode" type="password" canRevealPassword revealPasswordAriaLabel="Vis adgangskode" value={this.state.password} onChange={(ev, newValue) => { this.setState({password: newValue}); }} />              
                  <Link className={styles.lostPasswordLink} onClick={() => { this.setState({formType:formType.requestPassword}); }}>Glemt adgangskode? Aktiver min adgang igen</Link>
                <div>
                  <PrimaryButton type="submit" className={styles.marginBottom} disabled={this.state.isWorking || this.state.email == undefined || this.state.email == "" || this.state.password == undefined || this.state.password == ""} text={"Log på"} />
                  { this.state.showErrorMessage && 
                    <MessageBar
                      messageBarType={MessageBarType.blocked}
                      isMultiline={true}
                      className={styles.marginTop}
                    >
                      Forkert brugernavn og/eller adgangskode. <br/>Prøv venligst igen.
                    </MessageBar>
                  }
                </div>
              </form>
          </div>);
      } else if(this.state.formType == formType.requestPassword)
      {
          return (<div className={styles.login}>
            <form onSubmit={this.onRequestNewPassword.bind(this)}>
              <h3>Aktiver min adgang igen</h3>
                  <TextField className={styles.marginBottom} placeholder="E-mail" value={this.state.email} onChange={(ev, newValue) => { this.setState({email: newValue}); }} />          
                <div>
                  <PrimaryButton type="Submit" className={styles.marginRight} disabled={this.state.isWorking || this.state.email == undefined || this.state.email == ""} text={"Send aktiveringslink"} />                  
                  {this.state.showRequestPasswordStatus && 
                    <div className={styles.marginTop}>
                      Der er nu sendt et aktiveringslink til din e-mail. Følg instruktionerne for at nulstille din adgangskode og aktivere din bruger. 
                    </div>}
                </div>
              </form>
          </div>);
      } else if(this.state.formType == formType.activateAccount)
      {
        return (<div className={styles.login}>
          <form onSubmit={this.onResetPassword.bind(this)}>
            <h3>Nulstil din adgangskode</h3>
            <TextField 
                className={styles.marginBottom}
                required={false} 
                placeholder={"Angiv din nye adgangskode" }
                type="password" 
                autoComplete="new-password"
                canRevealPassword 
                revealPasswordAriaLabel="Vis adgangskode" 
                value={this.state.newPassword} 
                onChange={(ev, newValue) => { this.setState({ newPassword: newValue }); }}
            />    
            <TextField 
                className={styles.marginBottom}
                required={false} 
                placeholder={"Angiv din nye adgangskode igen" }
                validateOnLoad={false}
                type="password" 
                autoComplete="new-password"                
                canRevealPassword 
                revealPasswordAriaLabel="Vis adgangskode" 
                value={this.state.newPasswordAgain} 
                onGetErrorMessage={this.validateChangePassword.bind(this)}
                onChange={(ev, newValue) => { this.setState({ newPasswordAgain: newValue }); }}
            />  
              <div>
                <PrimaryButton type="Submit" className={styles.marginRight} disabled={this.state.isWorking || this.state.newPassword == undefined || this.state.newPasswordAgain == undefined} text={"Nulstil adgangskode"} />                  
                {this.state.showResetPasswordStatus && 
                  <div className={styles.marginTop}>
                    Din adgangskode er nu ændret. <Link onClick={() => { window.location.href = "/"; }}>Log på igen</Link> 
                  </div>}
              </div>
            </form>
        </div>);
      }

      return <React.Fragment />
    }

    private validateChangePassword()
    {        
        let isValid : boolean = false;

        if(!this.state.newPassword || !this.state.newPasswordAgain)
        {
            isValid = false;
        } else
        {
            isValid = this.state.newPassword === this.state.newPasswordAgain;
        }

        this.setState({canResetPassword: isValid });

        return isValid ? "" : "Din nye adgangskode er ikke angivet ens.";
    }

    private async onSubmit(e:any)
    {
      e.preventDefault();

      this.setState({isWorking: true}, async () => {
        let user : IUser | undefined = await this.userService.Login({ email: this.state.email, password: this.state.password } as ILoginRequestDTO);

        if(!user)
        {
          this.setState({showErrorMessage: true, isWorking: false});
        } else {
          this.props.setToken(user);
        }   
      });   
    }

    private async onRequestNewPassword(e:any)
    {
      e.preventDefault();

      this.setState({isWorking: true}, async () => {
        let result = await this.userService.RequestNewPassword({ email: this.state.email } as IRequestNewPasswordDTO);

        if(!result)
        {
          alert('Der er sket en fejl under behandlingen af din anmodning omkring nulstilling af adgangskode. Kontakt venligst Dalgas.');
        } else
        {
          this.setState({showRequestPasswordStatus: true});
        } 
      }); 
    }

    private async onResetPassword(e:any)
    {
      e.preventDefault();

      this.setState({isWorking: true}, async () => {
        let result = await this.userService.ResetPassword({ prFlowId: this.prflowId, password: this.state.newPassword } as IResetPassword);

        if(!result)
        {
          alert('Der er sket en fejl under nulstillingen af din adgangskode. Kontakt venligst Dalgas.');
        } else {
          this.setState({showResetPasswordStatus: true, isWorking: false});
        }
      });      
    }
}