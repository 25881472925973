import React from 'react';
import { PrimaryButton, DefaultButton, Panel, PanelType, Stack, TextField } from '@fluentui/react';
import styles from './AnnouncementPanel.module.scss';
import { IAnnouncementDTO, IDeleteAnnouncementDTO } from '../../../models';
import { IAnnouncementService, Services } from '../../../services';
import { UserServiceProvider, IUserService } from '../../../services/userService';

interface IAnnouncementPanelProps
{
    service: IAnnouncementService;
    selectedAnnouncement? : IAnnouncementDTO;
    onDismiss(): void;
    onChanged(user: IAnnouncementDTO, reason: string): void;
}

interface IAnnouncementPanelState
{
    currentItem : IAnnouncementDTO;
    canSubmit: boolean;
}

export enum PanelCloseReason
{
    AddOrUpdate = "AddOrUpdate",
    Delete = "Delete",
}

export default class AnnouncementPanel extends React.Component<IAnnouncementPanelProps, IAnnouncementPanelState> {        
    private userService : IUserService;
    
    constructor(props: IAnnouncementPanelProps) {
        super(props);

        this.userService = Services.UserService.Initialize(new UserServiceProvider());
        
        this.state = {
            canSubmit: true,
            currentItem: props.selectedAnnouncement ? props.selectedAnnouncement : { text: "" } as IAnnouncementDTO
        };
    }

    public render(): React.ReactElement<IAnnouncementPanelProps> {        
        return (
            <Panel
              headerText={this.props.selectedAnnouncement ? "Rediger besked" : "Opret ny besked"}
              isOpen={true}  
              isBlocking={true}   
              className={styles.announcementPanel}                 
              isLightDismiss={false}              
              onRenderFooterContent={() => {
                  return (<div>
                    <PrimaryButton disabled={!this.state.canSubmit} style={{marginRight:"10px"}} 
                        onClick={() => {this.onAddOrUpdateAnnouncement()}}
                        >
                        {this.props.selectedAnnouncement ? "Gem" : "Opret"}
                    </PrimaryButton>
                    { this.props.selectedAnnouncement && 
                        <PrimaryButton 
                            className={styles.deleteButton} 
                            onClick={async () => { this.deleteAnnouncement(); } }
                            >
                            Slet besked
                        </PrimaryButton> 
                    }
                    <DefaultButton onClick={() => this.props.onDismiss()}>Annuller</DefaultButton>
                </div>);                
                }
              }
              onDismiss={() => this.props.onDismiss()}              
              closeButtonAriaLabel="Luk beskedpanel"
              type={PanelType.medium}
            >                
                <Stack>
                    <TextField 
                        required={true}                         
                        label="Tekst" 
                        placeholder="Angiv den besked, som ønskes vist" 
                        autoComplete="off_announcement"
                        multiline={true}
                        rows={6}
                        value={this.state.currentItem.text} 
                        onChange={(ev, newValue) => { this.setState({ currentItem: { ...this.state.currentItem, text: newValue + "" }}); }}
                    />    
                </Stack>
            </Panel>
        );
    }

    private async onAddOrUpdateAnnouncement()
    {
        let result : boolean = false;
        if(this.props.selectedAnnouncement)
        {
            result = await this.props.service.UpdateAnnouncement(this.state.currentItem);
        } else
        {
            result = await this.props.service.CreateAnnouncement(this.state.currentItem);
        }

        if(result)
        {
            this.props.onChanged(this.state.currentItem, PanelCloseReason.AddOrUpdate);
        } else
        {
            alert("Fejl ved oprettelse eller opdatering af beskeden. Prøv igen senere.");
        }
    }

    private async deleteAnnouncement()
    {
        if(window.confirm("Er du sikker på, at du ønsker at slette denne besked?"))
        {
            let result : boolean = false;
            if(this.props.selectedAnnouncement)
            {
                result = await this.props.service.DeleteAnnouncement({ id: this.state.currentItem.id + "" } as IDeleteAnnouncementDTO);
            } 
    
            if(result)
            {
                this.props.onChanged(this.state.currentItem, PanelCloseReason.Delete);
            } else
            {
                alert("Der er sket en fejl ved sletning af beskeden. Prøv igen senere.");
            }
        }        
    }
}