import React from 'react';
import { CommandBar, ICommandBarItemProps, SelectionMode, DetailsListLayoutMode, DetailsList, IColumn, Pivot, PivotItem, ShimmeredDetailsList, ConstrainMode, SearchBox, IDetailsListProps, IDetailsRowStyles, DetailsRow } from '@fluentui/react';
import { IAssignmentDTO } from '../../models';
import AssignmentPanel from './AssignmentPanel/AssignmentPanel';
import { Services } from '../../services';
import App from '../../App';
import styles from './Dashboard.module.scss';
import Utilities, { Status } from '../../helpers/Utilities';
import { AssignmentServiceProvider, IAssignmentService } from '../../services/assignmentService';

interface IDashboardState
{
    isAssignmentsLoading: boolean;
    assignments: IAssignmentDTO[];
    showAssignmentPanel: boolean;
    selectedAssignment?: IAssignmentDTO; 
    assignment_Columns: IColumn[];
    assignment_Columns_Approved: IColumn[];
    assignment_Columns_DL: IColumn[];
    assignment_Columns_DL_Approved: IColumn[];
    assignment_Columns_Admin: IColumn[];
    createOnbehalfOfSupplier: boolean;
    currentPivot?: string;
    searchTerm?: string;
}

interface IDashboardProps
{

}

export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  private assignmentService : IAssignmentService;
  private _allItems: IAssignmentDTO[] = [] as IAssignmentDTO[];
  
  constructor(props : IDashboardProps) {
    super(props);
    
    this.assignmentService = Services.AssignmentService.Initialize(new AssignmentServiceProvider());
    
    const assignment_Columns: IColumn[] = [
      {
        key: 'created',
        name: 'Dato',
        fieldName: 'created',
        minWidth: 80,
        maxWidth: 80,
        onRender: (item: IAssignmentDTO) => {          
          if(item.approvedDate)
          {
            return item.approvedDate.toLocaleDateString();
          }

          return item.created?.toLocaleDateString();
        },
        onColumnClick: this._onColumnClick.bind(this),
      },      
      {
        key: 'orderName',
        name: 'Opgavenavn',
        fieldName: 'orderName',
        minWidth: 400,
        maxWidth: 400,
        isResizable: true,
        onColumnClick: this._onColumnClick.bind(this),
      },
      {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: this._onColumnClick.bind(this),
        onRender: (item: IAssignmentDTO) => {
          switch(item.status)
          {
            case Status.Rejected:
              return <span className={styles.rejected}>{item.status}</span>;
            case Status.RequiresAdditionalInfo:
              return <span className={styles.requiresAdditionalInfo}>{item.status}</span>;
          }
          return <span>{item.status}</span>
        }
      },   
      {
        key: 'estimatedAmount',
        name: 'Mængde',
        fieldName: 'estimatedAmount',
        minWidth: 125,
        maxWidth: 125,
        onRender: (item: IAssignmentDTO) => {
          return item.estimatedAmount ? `${item.estimatedAmount} rm` : "";
        },
        onColumnClick: this._onColumnClick.bind(this),
      }  
    ];

    const assignment_Columns_Approved = assignment_Columns.slice();
    assignment_Columns_Approved.splice(2, 0, {
      key: 'hdaoNo',
      name: 'HD AO nr.',
      fieldName: 'hdaoNo',        
      minWidth: 100,      
      maxWidth: 100,
      onRender: (item: IAssignmentDTO) => {
        return item.hdaoNo?.toLocaleString();
      },
      onColumnClick: this._onColumnClick.bind(this),
    });

    const assignment_Columns_DL: IColumn[] = [
      {
        key: 'created',
        name: 'Dato',
        fieldName: 'created',
        minWidth: 80,
        maxWidth: 80,
        onRender: (item: IAssignmentDTO) => {
          if(item.approvedDate)
          {
            return item.approvedDate.toLocaleDateString()
          }

          return item.created?.toLocaleDateString();
        },
        onColumnClick: this._onColumnClickDL.bind(this),
      },  
      {
        key: 'supplierName',
        name: 'Leverandør',
        fieldName: 'supplierName',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        onColumnClick: this._onColumnClickDL.bind(this),
      },      
      {
        key: 'orderName',
        name: 'Opgavenavn',
        fieldName: 'orderName',
        minWidth: 400,
        maxWidth: 400,
        isResizable: true,
        onColumnClick: this._onColumnClickDL.bind(this),
      },
      {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: this._onColumnClickDL.bind(this),
        onRender: (item: IAssignmentDTO) => {
          switch(item.status)
          {
            case Status.Rejected:
              return <span className={styles.rejected}>{item.status}</span>;
            case Status.RequiresAdditionalInfo:
              return <span className={styles.requiresAdditionalInfo}>{item.status}</span>;
          }
          return <span>{item.status}</span>
        }
      },  
      {
        key: 'estimatedAmount',
        name: 'Mængde',
        fieldName: 'estimatedAmount',
        minWidth: 125,
        maxWidth: 125,
        onRender: (item: IAssignmentDTO) => {
          return item.estimatedAmount ? `${item.estimatedAmount} rm` : "";
        },
        onColumnClick: this._onColumnClickDL.bind(this),
      }  
    ];

    const assignment_Columns_DL_Approved = assignment_Columns_DL.slice();
    assignment_Columns_DL_Approved.splice(3, 0, {
      key: 'hdaoNo',
      name: 'HD AO nr.',
      fieldName: 'hdaoNo',        
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: IAssignmentDTO) => {
        return item.hdaoNo?.toLocaleString();
      },
      onColumnClick: this._onColumnClickDL.bind(this),
    });

    const assignment_Columns_Admin = assignment_Columns_DL_Approved.slice();
    assignment_Columns_Admin.push({
      key: 'region',
      name: 'Region',
      fieldName: 'regionName',        
      minWidth: 100,
      maxWidth: 100,
      onColumnClick: this._onColumnClickDL.bind(this),
    });

    this.state = {        
      assignments: [] as IAssignmentDTO[],
      isAssignmentsLoading:true,
      showAssignmentPanel: false,
      assignment_Columns: assignment_Columns,
      assignment_Columns_Approved: assignment_Columns_Approved,
      assignment_Columns_DL: assignment_Columns_DL,
      assignment_Columns_DL_Approved: assignment_Columns_DL_Approved,
      assignment_Columns_Admin: assignment_Columns_Admin,
      createOnbehalfOfSupplier: false
    }
  }

  public componentDidMount()
  {
    this.getData(false);
  }

  public render(): React.ReactElement<IDashboardProps> {    
    if(App.IsUserInRole("DL"))
    {
      return this.renderForDL();
    } 

    if(App.IsUserInRole("Finance") || App.IsUserInRole("Admin"))
    {
      return this.renderForAdmin();
    }

    return this.renderForSupplier();
  }
  
  private renderForSupplier() : React.ReactElement<IDashboardProps>
  {
    const CommandBar_Assignments: ICommandBarItemProps[] = [      
      {
        key: 'newAssignment',
        text: 'Ny opgave',
        iconProps: { iconName: 'Add' },
        disabled: this.state.isAssignmentsLoading,
        onClick: () => { this.setState({selectedAssignment: undefined, showAssignmentPanel: true }) }  
    }
    ];

    return (
        <div className={styles.dashboard}>
            <div className={styles.heading}><h3>Opgaveoverblik</h3></div>          
            <CommandBar className={styles.assignmentsCommandBar} items={CommandBar_Assignments} styles={{root: {paddingLeft:"0px"}}} farItems={this.CreateFarMenuItems()} />
            <Pivot selectedKey={this.state.currentPivot} onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => { this.preload(item); }}>
              <PivotItem itemKey={"Pivot_1"} headerText={`Mine opgaver (${this._allItems.filter(d => d.status !== Status.Approved).length})`}>
                  <div style={{margin:"20px"}}>                    
                    <ShimmeredDetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status !== Status.Approved)}
                        columns={this.state.assignment_Columns}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        enableShimmer={this.state.isAssignmentsLoading}
                        setKey="set"
                      />
                  </div>
              </PivotItem>
              <PivotItem itemKey={"Pivot_2"} headerText={`Opgaver godkendt af HD (${this._allItems.filter(d => d.status == Status.Approved).length})`}>                
                  <div style={{margin:"20px"}}>                      
                      <DetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status == Status.Approved)}
                        columns={this.state.assignment_Columns_Approved}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="setApproved"
                      />
                  </div>
              </PivotItem>
            </Pivot>
            {this.state.showAssignmentPanel && <AssignmentPanel allowSelectionOfSupplier={false} selectedAssignment={this.state.selectedAssignment} onChanged={(assignment: IAssignmentDTO, showAssignmentPanel) => {  this.setState({selectedAssignment: assignment}); this.getData(showAssignmentPanel); }} onDismiss={() => { this.setState({showAssignmentPanel: false}); this.getData(false); } } />}            
        </div>
    );
  }

  private renderForDL() : React.ReactElement<IDashboardProps>
  {
    const CommandBar_Assignments: ICommandBarItemProps[] = [      
      {
        key: 'new',
        text: 'Ny opgave på vegne af leverandør',
        iconProps: { iconName: 'Add' },
        disabled: this.state.isAssignmentsLoading,
        onClick: () => { this.setState({selectedAssignment: undefined, createOnbehalfOfSupplier: true, showAssignmentPanel: true }) }      
      }
    ];

    return (
        <div className={styles.dashboard}>
            <div className={styles.heading}><h3>Aktuelle opgaver i min region</h3></div>
            <CommandBar className={styles.assignmentsCommandBar} items={CommandBar_Assignments} styles={{root: {paddingLeft:"0px"}}} farItems={this.CreateFarMenuItems()} />    
            <Pivot selectedKey={this.state.currentPivot} onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => { this.preload(item); }}>
              <PivotItem itemKey={"Pivot_DL_1"} headerText={`Opgaver til godkendelse (${this._allItems.filter(d => d.status === Status.WaitingForApproval).length})`}>
                  <div style={{margin:"20px"}}>                  
                    <ShimmeredDetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status === Status.WaitingForApproval)}
                        columns={this.state.assignment_Columns_DL}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}                        
                        isHeaderVisible={true}
                        enableShimmer={this.state.isAssignmentsLoading}
                        setKey="set"
                      />
                  </div>
              </PivotItem>
              <PivotItem itemKey={"Pivot_DL_2"} headerText={`Opgaver som mangler afklaring (${this._allItems.filter(d => d.status == Status.RequiresAdditionalInfo).length})`}>                
                  <div style={{margin:"20px"}}>
                      <DetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status == Status.RequiresAdditionalInfo)}
                        columns={this.state.assignment_Columns_DL_Approved}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="setRequiresAdditionalInfo"
                      />
                  </div>
              </PivotItem>
              <PivotItem itemKey={"Pivot_DL_3"} headerText={`Afviste opgaver (${this._allItems.filter(d => d.status == Status.Rejected).length})`}>                
                  <div style={{margin:"20px"}}>
                      <DetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status == Status.Rejected)}
                        columns={this.state.assignment_Columns_DL_Approved}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="setRejected"
                      />
                  </div>
              </PivotItem>
              <PivotItem itemKey={"Pivot_DL_4"} headerText={`Godkendte opgaver (${this._allItems.filter(d => d.status == Status.Approved).length})`}>                
                  <div style={{margin:"20px"}}>
                      <DetailsList
                        className={styles.tasksList}
                        items={this.state.assignments.filter(d => d.status == Status.Approved)}
                        columns={this.state.assignment_Columns_DL_Approved}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="setApproved"
                      />
                  </div>
              </PivotItem>
            </Pivot>
            {this.state.showAssignmentPanel && <AssignmentPanel allowSelectionOfSupplier={this.state.createOnbehalfOfSupplier} selectedAssignment={this.state.selectedAssignment} onChanged={(assignment: IAssignmentDTO, showAssignmentPanel: boolean) => { this.getData(showAssignmentPanel); this.setState({selectedAssignment: assignment}); }} onDismiss={() => { this.setState({showAssignmentPanel: false}); this.getData(false); } } />}            
        </div>
    );
  }

  private renderForAdmin() : React.ReactElement<IDashboardProps>
  {
    const CommandBar_Assignments: ICommandBarItemProps[] = [
    {
        key: 'openAssignment',
        text: 'Åbn',
        iconProps: { iconName: 'OpenPaneMirrored' },
        onClick: () => { this.setState({createOnbehalfOfSupplier: false, showAssignmentPanel: true}) },
        disabled: !this.state.selectedAssignment
    }
    ];

    return (
        <div className={styles.dashboard}>
            <div className={styles.heading}><h3>Alle opgaver</h3></div>
            <CommandBar className={styles.assignmentsContainer} items={CommandBar_Assignments} styles={{root: {paddingLeft:"0px"}}} farItems={this.CreateFarMenuItems()} />    
            <Pivot selectedKey={this.state.currentPivot} onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => { this.preload(item); }}>
              <PivotItem headerText={`Opgaver til godkendelse (${this._allItems.filter(d => d.status === Status.WaitingForApproval).length})`}>
                  <div style={{margin:"20px"}}>                  
                    <ShimmeredDetailsList
                        items={this.state.assignments.filter(d => d.status === Status.WaitingForApproval)}
                        columns={this.state.assignment_Columns_Admin}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        enableShimmer={this.state.isAssignmentsLoading}
                        setKey="set"
                      />
                  </div>
              </PivotItem>
              <PivotItem headerText={`Godkendte opgaver (${this._allItems.filter(d => d.status == Status.Approved).length})`}>                
                  <div style={{margin:"20px"}}>
                      <DetailsList
                        items={this.state.assignments.filter(d => d.status == Status.Approved)}
                        columns={this.state.assignment_Columns_Admin}
                        selectionMode={SelectionMode.none}
                        onRenderRow={this._onRenderRow?.bind(this)}
                        constrainMode={ConstrainMode.unconstrained}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        setKey="setApproved"
                      />
                  </div>
              </PivotItem>
            </Pivot>
            {this.state.showAssignmentPanel && <AssignmentPanel allowSelectionOfSupplier={this.state.createOnbehalfOfSupplier} selectedAssignment={this.state.selectedAssignment} onChanged={(assignment: IAssignmentDTO, showAssignmentPanel: boolean) => { this.getData(showAssignmentPanel); this.setState({selectedAssignment: assignment}); }} onDismiss={() => { this.setState({showAssignmentPanel: false}); this.getData(false); } } />}            
        </div>
    );
  }

  private preload(item?: PivotItem)
  {
    let pivotKey = item?.props.itemKey;
    let assignments = this._allItems;

    // Override sorting and default sort by approvedDate, desc on "Godkendt opgaver"-tab
    if(pivotKey == "Pivot_DL_4")
    {
      const columnKey = "approvedDate";
      const newColumns = this.GetUpdatedColumns(columnKey);
      this.setState({
        assignment_Columns_DL: newColumns,
      }, () => {
        assignments = Utilities.CopyAndSort(this.state.assignments.filter(d => d.status == Status.Approved), columnKey, true);  
      });      
    }

    this.setState({currentPivot: pivotKey, assignments: assignments});
  }

  private CreateFarMenuItems()
  {
    return [
      {        
        key:"search",
        onRender:() => <SearchBox 
          placeholder="Søg efter opgave ..." 
          className={styles.searchBox} 
          onSearch={(newValue) => { this.onSearch(newValue); } } 
          onChange={(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => { this.onSearch(newValue); }}
          onClear={ev => { this.onSearch(""); }}
          />
      }
    ];
  }

  private onSearch(searchTerm: string | undefined): void
  {
    this.setState({ searchTerm: searchTerm, assignments: searchTerm && searchTerm != "" ? this.filter(searchTerm) : this._allItems });
  }

  private filter(searchTerm: string) : IAssignmentDTO[]
  {
    if(searchTerm.length == 9 && searchTerm.indexOf(".") > -1)
    {
      searchTerm = searchTerm.replaceAll(".", "");
    }
    
    return this._allItems.filter(i => 
      (i.status + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
      (i.supplierName + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
      (i.orderName + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
      (i.regionName + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 || 
      (i.biomassCategory + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
      (i.hdaoNo + "").toLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
    );
  }

  private async getData(preservePanelOpen: boolean)
  {
    this.setState(
      {
        isAssignmentsLoading: true,
        showAssignmentPanel: preservePanelOpen  
      }
    );

    this._allItems = await this.assignmentService.GetAssignments();
    let assignments = this._allItems;

    assignments = this.TryUpdateDetailsListSortingFromLocalStorage();
    
    if(this.state.searchTerm && this.state.searchTerm != "")
    {
      assignments = this.filter(this.state.searchTerm);
    }

    let selectedIndex = assignments.findIndex(d => d.id == this.state.selectedAssignment?.id)

    this.setState(
      {
        assignments: assignments,
        isAssignmentsLoading: false,
        showAssignmentPanel: preservePanelOpen,
        selectedAssignment: assignments[selectedIndex]
      }
    );
  }

  private TryUpdateDetailsListSortingFromLocalStorage() : IAssignmentDTO[]
  {
    const currColumn = JSON.parse(localStorage.getItem(`Flisportal_RecentSortColumn`) + "") as IColumn;
    if(currColumn)
    {
      const newColumns = this.GetUpdatedColumns(currColumn.key);
      const assignments = Utilities.CopyAndSort(this._allItems, currColumn.fieldName!, currColumn.isSortedDescending);

      if(App.IsUserInRole("DL"))
      {
        this.setState({
          assignment_Columns_DL: newColumns,
        });
      } else
      {
        this.setState({
          assignment_Columns: newColumns,
        });
      }    
      
      return assignments;
    }

    return this._allItems
  }

  private _onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn)
  {    
    const newColumns = this.GetUpdatedColumns(column.key);
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

    localStorage.setItem(`Flisportal_RecentSortColumn`, JSON.stringify(currColumn));
    this._allItems = Utilities.CopyAndSort(this.state.assignments, currColumn.fieldName!, currColumn.isSortedDescending);

    this.setState({
      assignment_Columns: newColumns,
      assignments: this._allItems
    });
  }

  private _onColumnClickDL(ev: React.MouseEvent<HTMLElement>, column: IColumn)
  {
    const newColumns = this.GetUpdatedColumns(column.key);
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

    localStorage.setItem(`Flisportal_RecentSortColumn`, JSON.stringify(currColumn));
    this._allItems = Utilities.CopyAndSort(this.state.assignments, currColumn.fieldName!, currColumn.isSortedDescending);

    this.setState({
      assignment_Columns_DL: newColumns,
      assignments: this._allItems
    });
  }

  private GetUpdatedColumns(columnKey: string) : IColumn[]
  {
    const newColumns: IColumn[] = this.getColumnsForCurrentPivot().slice();
    const currColumn: IColumn = newColumns.filter(currCol => columnKey === currCol.key)[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    return newColumns;
  }

  private getColumnsForCurrentPivot() : IColumn[]
  {
    let columns = this.state.assignment_Columns;

    if(App.IsUserInRole("DL"))
    {
      columns = this.state.assignment_Columns_DL;
    } else if(App.IsUserInRole("Admin"))
    {
      columns = this.state.assignment_Columns_Admin;
    }

    switch(this.state.currentPivot)
    {
      case "Pivot_2":
        columns = this.state.assignment_Columns_Approved;
        break;
      case "Pivot_DL_2":
      case "Pivot_DL_3":
      case "Pivot_DL_4":
        columns = this.state.assignment_Columns_DL_Approved;
        break;
    }

    return columns;
  }

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      return <div title="Klik for at åbne opgave" onClick={() => { this.setState({selectedAssignment: props.item, showAssignmentPanel: true}); }}><DetailsRow {...props} styles={customStyles} /></div>
    }
    return null;
  }
}